<template>
  <div class="all">
    <!-- 导航 -->
    <van-nav-bar title="填写物流" left-arrow @click-left="onClickLeft" />
    <div class="container">
      <div class="item">
        <div class="_item">
          <div class="item-info">
            <img
              style="width: 80px; height: 80px"
              :src="this.addressData.orderItem.picUrl"
              alt
            />
            <div class="desc-spec">
              <div class="desc">
                <span style="display: inline-block; width: 180px"
                  >{{this.addressData.orderItem.spuName}}</span
                >
                <span>￥{{ this.addressData.orderItem.paymentPrice }}</span>
              </div>
              <div class="spec">
                <span>规格：{{ addressData.orderItem.specInfo == null || addressData.orderItem.specInfo == 'null' ? '统一规格' : addressData.orderItem.specInfo}}</span>
                <span>*{{ this.addressData.orderItem.quantity }}</span>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div class="address">
        <div class="address-info">
          <div class="address-left">
            <span>返回地址</span>
          </div>
          <div class="address-right">
            <div>
              <span>{{this.addressData.shopName}}</span>
              <span style="margin-left: 10px">{{this.addressData.telNum}}</span>
            </div>
            <div style="margin-top: 6px">{{this.addressData.address}}</div>
          </div>
        </div>
        <div class="track-num">
          <span>运单号码</span>
          <van-field type="number" v-model="trackNumValue" placeholder="请填写运单号码" />
        </div>
        <div class="track-company">
          <div class="only-return" @click="backReason">
            <span class="only">快递公司</span>
            <span v-if="chooseReason" style="color: #333333">{{
              chooseReason
            }}</span>
            <span v-else>请选择</span>
            <van-icon name="arrow" size="18" />
          </div>
        </div>
      </div>
      <!-- 按钮 -->
        <div class="commit-btn">
          <van-button
            type="primary"
            round
            color="#FB2B53 "
            block
            @click="commitAll"
            >提交</van-button
          >
        </div>
      <!-- 退货原因 -->
      <van-action-sheet
        :closeable="false"
        v-model="showBackReason"
        title="快递公司"
      >
        <div class="content">
          <div
            class="content-reason"
            @click="clickReason(item, index)"
            v-for="(item, index) in allReason"
            :key="index"
          >
            <van-icon
              :color="num == index ? '#FB2B53' : ''"
              name="checked"
              size="16"
            />
            <span>{{ item.reasonWord }}</span>
          </div>
        </div>
        <div class="commit-btn">
          <van-button
            type="primary"
            round
            color="#FB2B53 "
            block
            @click="commitReason"
            >提交</van-button
          >
        </div>
      </van-action-sheet>
    </div>
  </div>
</template>

<script>
import { addLogistics, getGoodsDetail } from "@/api/afterSales"
export default {
  data() {
    return {
      show: false,
      //  仅退货
      showBackReason: false,
      allReason: [
        {
          reasonWord: "顺丰速运",
        },
        {
          reasonWord: "韵达快递",
        },
        {
          reasonWord: "申通快递",
        },
        {
          reasonWord: "圆通速递",
        },
        {
          reasonWord: "德邦快递",
        },
        {
          reasonWord: "百世汇通",
        },
        {
          reasonWord: "邮政快递",
        },
      ],
      trackNumValue: "", //运货单号
      chooseReason: null, //退货原因
      num: -1, //退货原因索引写活一个
      color: "", //颜色
      isReason: "", //请选择显示的是不是退货原因
      addressData: {}
    };
  },
  created() {
    this.$store.commit("setSession", this.$route.query.session)
    this.getGoodsDetail()
  },
  methods: {
    getGoodsDetail () {
      let id = this.$route.query.id;
      getGoodsDetail(id).then(res => {
        this.addressData = res.data.data
      })
    },

    //弹出退货原因
    backReason() {
      this.showBackReason = true;
    },
    //点击退货原因
    clickReason(item, index) {
      this.num = index;
      this.chooseReason = item.reasonWord;
    },
    // 提交退货原因
    commitReason() {
      this.showBackReason = false;
    },
    // 页面提交物流
    commitAll() {
      // 提交物流申请
       if(this.trackNumValue == null || this.trackNumValue == '') {
         return this.$Toast("请填写运单号")
       }
      //  if(this.trackNumValue.length <=14) {
      //    return this.$Toast("运单号不小于14位数")
      //  }
       if(this.chooseReason == null || this.chooseReason == '') {
         return this.$Toast("请选择物流信息")
       }
       let logisticsLetter = ""
       if(this.chooseReason == "顺丰速递") {
          logisticsLetter = "shunfeng"
       } else if(this.chooseReason == "韵达快递") {
          logisticsLetter = "yunda"
       } else if(this.chooseReason == "申通快递") {
          logisticsLetter = "shentong"
       } else if(this.chooseReason == "圆通速递") {
          logisticsLetter = "yuantong"
       } else if(this.chooseReason == "德邦快递") {
          logisticsLetter = "debangkuaidi"
       } else if(this.chooseReason == "百世汇通") {
          logisticsLetter = "huitongkuaidi"
       } else if(this.chooseReason == "邮政快递") {
          logisticsLetter = "ems"
       }
      let params = {
         userName: this.addressData.shopName,
         telNum: this.addressData.telNum,
         address: this.addressData.address,
         logisticsNo:  this.trackNumValue,
         logistics: logisticsLetter, //物流名称
         orderRefundsId: this.$route.query.id
       }
       addLogistics(params).then(res => {
         if(res.data.code === 0) {
           this.$Toast("填写物流成功成功")
           this.$router.replace({
            path: '/returnMoneyDetail',
            query: {
              id: this.$route.query.id,
              session: this.$route.query.session
            }
          })
          //  localStorage.setItem("logisticsData", JSON.stringify(res.data.data))
         }
       })
      //  this.$router.push({
      //    path: "/returnGoodsDetail",
      //    query: {
      //      logistics: logisticsLetter,
      //      logisticsNo: this.trackNumValue
      //    }
      //  });
      //  localStorage.setItem('logistics', logisticsLetter)
      //  localStorage.setItem('logisticsNo', this.trackNumValue)
    },
    onClickLeft() {
      if(this.$route.query.first == 0) {
         this.$router.back(-1)
      } else{
        let u = navigator.userAgent;
        let isAndroid = u.indexOf("Android") > -1 || u.indexOf("Linux") > -1; //android
        let isIOS = !!u.match(/\(i[^;]+;( U;)? CPU.+Mac OS X/); //ios终端
        if (isIOS) {
          window.webkit.messageHandlers.close.postMessage("up");
        }
        if (isAndroid) {
          window.android.close();
        }
      }
    },
  },
};
</script>

<style lang="less" scoped>
/deep/ .van-nav-bar {
  position: fixed;
  width: 100%;
  top: 0;
}
.container {
  margin-top: 36px;
  width: 100%;
  // height: 100vh;
  height: calc(100vh - 46px);
  background-color: #f1f1f1;
  box-sizing: border-box;
  padding: 10px 0;
  .item {
    background-color: #fff;
    width: 100%;
    height: 130px;
    box-sizing: border-box;
    padding: 20px 20px 0;
    margin: 10px 0;
    // margin-top: 15px;
    ._item {
      .title {
        font-size: 17px;
      }
      .item-info {
        margin-top: 10px;
        display: flex;
        .desc-spec {
          margin-left: 10px;
          flex: 1;
          .desc {
            display: flex;
            justify-content: space-between;
          }
          .spec {
            margin-top: 10px;
            color: #999999;
            font-family: PingFang-SC-Regular;
            display: flex;
            justify-content: space-between;
          }
        }
      }
    }
  }
  .address {
    background-color: #fff;
    .address-info {
      padding: 15px;
      display: flex;
      border-bottom: 1px solid #e8e8e8;
      .address-left {
        width: 58px;
        flex-shrink: 0;
      }
      .address-right {
        margin-left: 10px;
      }
    }
    .track-num {
      padding: 6px 15px 10px;
      border-bottom: 1px solid #e8e8e8;
      display: flex;
      align-items: center;
      .van-field {
        width: 200px;
      }
    }
    .track-company {
      // padding: 6px 15px 15px;
      .only-return {
        box-sizing: border-box;
        padding: 15px;
        // border-bottom: 1px solid #f1f1f1;
        color: #999999;
        .only {
          flex: 1;
          color: #333333;
        }
        height: 60px;
        display: flex;
        justify-content: space-between;
        align-items: center;
      }
    }
  }
}
.content {
  margin: 0 15px 60px;
  .content-reason {
    padding: 13px 0;
    // border-bottom: 1px solid #e8e8e8;
    display: flex;
    align-items: center;
    .van-icon {
      margin-right: 8px;
    }
  }
}
.all {
  position: relative;
}
.commit-btn {
  margin: 15px 15px;
  position: absolute;
  width: 90%;
  bottom: 0;
}
</style>